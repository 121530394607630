angular.module('headart')       //remember, without [] a new module is not registered and instead an existing one is used
.service('MetaData', ["$state", function ($state) {
    var self = this;
    var defaults = {
        title: "Digital Magazine",
        description: "Digital Magazine",
        author: "",
        site_name: "",
        images: [],                     // images used for preview

        og_locale: "en_GB",
        og_type: "website",
        // og_url: null,                // handled by backend
        // og_title: "",                // same as title
        // og_description: "",          // same as description
        // og_site_name: "",            // same as site_name


        twitter_card: "summary",
        // twitter_title: "",           // same as title
        // twitter_description: "",     // same as description
        twitter_site: "",               // twitter @handle of site
        twitter_creator: "",            // twitter @handle of content creator
 
        apple_touch_icon_76: "",
        apple_touch_icon_120: "",
        apple_touch_icon_152: "",
        apple_touch_icon_180: "",
    };
    this.defaults = defaults;

    this.setDefaults = function (data) {
        angular.forEach(defaults, function(value, key) {
            if (key in data) {
                self.defaults[key] = data[key];
            } else {
                self.defaults[key] = self.defaults[key];
            }
        });
    };

    this.applyMeta = function (data) {
        if (typeof data != "undefined") {
            angular.forEach(defaults, function(value, key) {
                if (key in data) {
                    self.meta[key] = data[key];
                } else {
                    self.meta[key] = self.defaults[key];
                }
            });
        } else {
            this.meta = angular.extend({}, this.defaults);
        }
    };

    // set up the defaults on init of singleton
    this.setDefaults(defaults);
    this.applyMeta();
}]);