/* Services */
angular.module('headart.auth.services', [])
.factory('ServerAuth', ["$resource", "api_url", function($resource, api_url) {
    return $resource(api_url+'auth/login', {}, {
        //additional actions
        login: {
            method:'POST',
        },
        logout: {
            method:'GET',
            url:api_url+'auth/logout',
        },
        check: {
            method:'GET',
            url:api_url+'auth/check',
        },
    });
}])
.service('AuthService', ["$rootScope", "$state", "$q", "$timeout", "$interval", "$http", "ServerAuth", "webStorage", function($rootScope, $state, $q, $timeout, $interval, $http, ServerAuth, webStorage) {
    var AuthService = function() {
        this.isLoggedIn = webStorage.get('isLoggedIn');                 //current login in state
        this.currentUser = webStorage.get('currentUser');               //current logged in user's details
        this.currentPermissions = webStorage.get('currentPermissions'); //stores a merged list of user's permissions
        this.currentGroups = webStorage.get('currentGroups');           //stores an array of the user's group membership

        this.redirect = null;         //stores redirect state and params for post login
        this.pollInterval = null;   //interval for login polling
    };

    AuthService.prototype.setUser = function(user, permissions) {
        var self = this;

        webStorage.set('isLoggedIn', true);
        webStorage.set('currentUser', user);
        webStorage.set('currentPermissions', permissions);
        //webStorage.set('currentGroups', groups);
        self.update();
    };

    AuthService.prototype.update = function() {
        //updates values in this singleton from session storage
        this.isLoggedIn = webStorage.get('isLoggedIn');
        this.currentUser = webStorage.get('currentUser');
        this.currentPermissions = webStorage.get('currentPermissions');
        this.currentGroups = webStorage.get('currentGroups');
    };

    AuthService.prototype.reset = function() {
        webStorage.clear();
        this.update();
    };

    AuthService.prototype.login = function(credentials) {
        var self = this;

        return ServerAuth.login(credentials).$promise
        .then(function(response) {
            // Success! store the user's details
            self.setUser(response.user, response.permissions);

            // broadcast event
            $rootScope.$broadcast('auth:login-confirmed', response);

            return response;
        })
        .catch(function(error) {
            return $q.reject(error);
        });
    };

    AuthService.prototype.logout = function() {
        var self = this;

        return ServerAuth.logout().$promise
        .then(function(response) {
            // Reset stored data
            self.reset();

            // broadcast event
            $rootScope.$broadcast('auth:logout-confirmed');

            return response;
        })
        .catch(function(error) {
            return $q.reject(error);
        });
    };

    AuthService.prototype.check = function() {
        var self = this;

        return ServerAuth.check().$promise
        .then(function(response) {
            // update stored data
            self.setUser(response.user, response.permissions);

            return response;
        })
        .catch(function(error) {
            return $q.reject(error);
        });
    };

    AuthService.prototype.startAuthPolling = function() {
        var self = this;

        self.pollInterval = $interval(function() {
            self.check().catch(function(error) {
                // broadcast event if login required
                if (error.status == 401 && $state.current.data.access == "private") {
                    self.redirect = {toState: $state.current.name, toParams: $state.params};

                    $rootScope.$broadcast('auth:login-required', self.redirect);
                    //$state.go('app.login');
                }
            });
        }, 30000);

    };

    AuthService.prototype.authorise = function(permission){
        //authorise the current user against the given permission
        if (this.currentPermissions == null) {
            return false;
        }
        if (!!this.currentPermissions['superuser']) {
            //super user
            return true;
        }
        return !!this.currentPermissions[permission];
    };

    /**
     * sets up the auth service instance, returning itself for chaining
     * @return {obj} self
     */
    AuthService.prototype.init = function() {
        var self = this;

        //start polling for login status against current state access
        self.startAuthPolling();

        //listen for state change events, and check access rights of logged in user
        $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
            if (toState.data.access == "public") {
                return false;
            } else if (!self.isLoggedIn) {
                // if the user is not logged in, prevent transition and broadcast a "login required" event           
                event.preventDefault();
                self.redirect = {toState: toState, toParams: toParams};

                $rootScope.$broadcast('auth:login-required', self.redirect);
            }
        });

        return self;
    };

    return new AuthService().init();
}]);