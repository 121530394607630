angular.module('headart.auth.directives', [])
.directive('authLogin', ["AuthService", function (AuthService) {
    //displayed when the app requires authentication
    return function (scope, element, attrs) {
        //once Angular is started, remove class:
        //element.removeClass('waiting-for-angular');
        scope.destination = null;
        scope.toParams = null;
        
        scope.$on('event:auth-loginRequired', function(event, args) {
            //login required
            //if 403, indicate permission is denied
            if(args.status == '403'){
                //...
            }
            //if 401, clear stored user details
            if(args.status == '401'){
                AuthService.reset();
            }
            
            //show the login popup
            element.addClass('show');
            console.log('Auth is required!', args);
            //if a toState has been passed with the event, store this for transition upon login success
            //or if the current state is the login page, redirect to the dashboard
            //(otherwise the auth service will re-attempt any http request automatically)
            if(args.toState !== undefined){
                scope.destination = args.toState.name;
                scope.toParams = args.toParams;
            }
            if(args.isLoggedIn == true){
                //if the user is logged in, give them the option to cancel
                scope.showCancelBtn = true;
            }
        });

        scope.$on('event:auth-loginConfirmed', function(event, args) {
            console.log('Auth complete!');
            console.log(args);
            element.removeClass('show');
        });

        scope.$on('event:auth-cancelLogin', function(event, args) {
            //hide the login form
            element.removeClass('show');
        });

    };
}]);