angular.module('headart.auth.controllers', [])
.controller('LoginCtrl', ["$scope", "$rootScope", "$state", "$log", "$timeout", "FormHelper", "AuthService", function($scope, $rootScope, $state, $log, $timeout, FormHelper, AuthService) {
    $scope.credentials = {
        email: null,
        password: null,
        remember: false,
        noAlert: true,       //stops the normal Alert Message from showing on errors
        error: null
    };
    $scope.result = {
        working: false,
    };

    $scope.reset = function(){
        //reset the credential values
        //$scope.credentials.email = null;  //leave the email for the next time login is required
        $scope.credentials.password = null;
        $scope.credentials.remember = false;
        $scope.credentials.noAlert = true;
        $scope.credentials.error = null;
    };

    $scope.cancel = function(){
        //cancel the login form
        $rootScope.$broadcast('event:auth-cancelLogin'); 
        //AlertMessage.clearMessages();
    };

    $scope.login = function(destination, toParams) {
        if ($scope.result.working) {
            return false;
        }

        //validate form
        if($scope.inputForm.$invalid){
            FormHelper.setAllDirty($scope.inputForm);
            return false;
        }

        //authenticate the user
        $scope.result = {working:true, message: "Authenticating..."};
        AuthService.login($scope.credentials)
            .then(function success(response) {
                $log.debug('user logged in', response.user);
                $scope.result = {working:false, message: "Success"};

                $timeout(function(){
                    //reset the form
                    $scope.result = {};
                    $scope.reset();
                    FormHelper.setAllPristine($scope.inputForm);

                    //clear alert messages
                    //AlertMessage.clearMessages();

                    //@TODO remove, in auth service now
                    //broadcast a 'loginConfirmed' event - this hides the login form
                    //AuthService.loginConfirmed(destination, toParams);

                    //if an explicit location has been set, go there (or go home if coming from login page)
                    if (destination) {
                        $state.go(destination, toParams);
                    } else if (AuthService.redirect) {
                        console.log('redirecting', AuthService.redirect, AuthService.redirect.toState);
                        $state.go(AuthService.redirect.toState, AuthService.redirect.toParams);
                        AuthService.redirect = null;
                    }
                    else if ($state.includes('app.login')) {
                        $state.go('app.account.home', toParams);
                    }
                }, 800);

            }, function error(response){
                //display the errors
                $scope.result = {working:false, message: "Login failed", errors: response.data.errors};
            });
    };
}]);