angular.module('headart')       //remember, without [] a new module is not registered and instead an existing one is used
.service('AlertMessage', ["config", function(config){
    this.config = {
        singleMode: false,      //only one message is shown at a time
        stackMode: false,       //multiple messages, stacked over each other
        cascadeMode: false,     //multiple messages, displayed one above the other
    };
    this.wrapperElement = $();  //element to append the messages to 
                                //can be set manually or automatically by the presence of the appMessage directive
    this.showMessage = function(content, cl, time) {
        var closeBtn = $('<a class="close">&times;</a>').on('click', function(){ $(this).parents('.alert').remove(); });
        var alert = $('<div class="alert alert-dismissable">')
            .addClass('message')
            .addClass('alert-'+cl)
            .html(content)
            .append(closeBtn)
            .hide().fadeIn('fast');
        if(this.config.singleMode){
            this.wrapperElement.html(alert);
        }
        else{
            alert.css('margin-bottom', '-34px');
            alert.appendTo(this.wrapperElement);
        }

        //use a timeout to automatically close the alert after a set time
        var closeAlert = setTimeout(function(){
            alert.fadeOut('fast', function() { $(this).remove(); });
        },time);
    };//end of showMessage()
    this.clearMessages = function(){
        //remove all messages
        this.wrapperElement.empty();
    };
}]);