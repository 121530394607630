angular.module('headart')
.directive('ngEnter', function() {
    //captures enter key press and evaluates the given arguments
    return function(scope, element, attrs) {
        element.bind("keydown keypress", function(event) {
            if (event.which == 13) {
                scope.$apply(function() {
                    scope.$eval(attrs.ngEnter, {$event: event});
                });
                event.preventDefault();
            }
        });
    };
})
/*
.directive('inScrollView', function (debounce) {
    //modifies the given scope value to boolean true if element is visible in scroll parent
    //NB: in long lists it's better to add to every nth element to reduce the number of event handlers running
    return {
        scope: {
            scrollElm: "@",
            model: "="
        },
        link: function (scope, element, attrs) {
            // check if we should use element or parent for scroll offset
            var useParent = !!scope.$eval(attrs.useParent);
            var scrollContainer = document.querySelector(scope.scrollElm);
            var scrollElement = useParent ? element[0].offsetParent : element[0];

            var checkIsVisible = debounce(function(event) {
                var topLimit = scrollContainer.scrollTop;
                var bottomLimit = scrollContainer.scrollTop + scrollContainer.clientHeight;
                var elmPosition = scrollElement.offsetTop;
                var isInView = scrollElement.offsetTop <= bottomLimit && scrollElement.offsetTop >= topLimit;

                scope.$evalAsync(function() {
                    scope.model = scrollElement.offsetTop <= bottomLimit && scrollElement.offsetTop >= topLimit;
                });
            }, 50);

            angular.element(scrollContainer).on('scroll', checkIsVisible);

            scope.$on("$destroy", function() {
                angular.element(scrollContainer).off('scroll', checkIsVisible);
            });
        }
    };
})
*/
.directive('domReady', ["$timeout", "$log", function ($timeout, $log) {
    //emits an event when the DOM update has completed on the last ng-repeat item
    return {
        link: function (scope, element, attrs) {

            var eventName = "dom-ready"
            if (attrs.domReady) {
                eventName += "-" + attrs.domReady;
            }
            if (scope.$last === true) {
                $timeout(function () {
                    $log.debug('dom is ready, sending event: ' + eventName);
                    scope.$emit(eventName, element);
                });
            }
        }
    };
}]);