angular.module('headart.error', [])

/*-----Error Pages-----*/
.controller('ErrorUnknownCtrl', ["$scope", "PhantomJs", function($scope, PhantomJs) {
    PhantomJs.htmlReady();
}])
.controller('Error404Ctrl', ["$scope", "PhantomJs", function($scope, PhantomJs) {
    PhantomJs.htmlReady();
}])
.controller('Error403Ctrl', ["$scope", "PhantomJs", function($scope, PhantomJs) {
    PhantomJs.htmlReady();
}])
.controller('Error401Ctrl', ["$scope", "PhantomJs", function($scope, PhantomJs) {
    PhantomJs.htmlReady();
}]);
