angular.module('headart')
.directive('metaData', ["$compile", "MetaData", function ($compile, MetaData) {
    return {
        restrict: 'A',
        controller: ["$scope", "$element", "$attrs", function($scope, $element, $attrs) {
            $scope.metadata = MetaData;
        }],
        compile: function compile(tElement, tAttrs, transclude) {
            var metaTemplate = ''
                +'<title>{{ metadata.meta.title }}</title>'
                +'<meta name="description" content="{{ metadata.meta.description }}">'
                +'<meta name="author" content="{{ metadata.meta.author }}">'

                +'<!-- Social Metas -->'
                +'<meta property="og:locale" content="{{ metadata.meta.og_locale }}">'
                +'<meta property="og:type" content="{{ metadata.meta.og_type }}">'
                +'<meta property="og:title" content="{{ metadata.meta.title }}">'
                +'<meta property="og:description" content="{{ metadata.meta.description }}">'
                +'<meta property="og:site_name" content="{{ metadata.meta.site_name }}">'
                +'<meta ng-repeat="image in metadata.meta.images" property="og:image" content="{{ image }}" ng-if="image">'

                +'<meta name="twitter:card" content="{{ metadata.meta.twitter_card }}">'
                +'<meta name="twitter:title" content="{{ metadata.meta.title }}">'
                +'<meta name="twitter:description" content="{{ metadata.meta.description }}">'
                +'<meta name="twitter:site" content="{{ metadata.meta.twitter_site }}">'
                +'<meta ng-repeat="image in metadata.meta.images" property="twitter:image:src" content="{{ image }}" ng-if="image">'
                +'<meta name="twitter:creator" content="{{ metadata.meta.twitter_creator }}">'
                
                +'<link rel="apple-touch-icon" sizes="76x76" href="{{ metadata.meta.apple_touch_icon_76 }}" ng-if="metadata.meta.apple_touch_icon_76">'
                +'<link rel="apple-touch-icon" sizes="120x120" href="{{ metadata.meta.apple_touch_icon_120 }}" ng-if="metadata.meta.apple_touch_icon_120">'
                +'<link rel="apple-touch-icon" sizes="152x152" href="{{ metadata.meta.apple_touch_icon_152 }}" ng-if="metadata.meta.apple_touch_icon_152">'
                +'<link rel="apple-touch-icon" sizes="180x180" href="{{ metadata.meta.apple_touch_icon_180 }}" ng-if="metadata.meta.apple_touch_icon_180">';

            return {
                pre: function preLink(scope, iElement, iAttrs, controller) {
                    // add the metadata template to the head
                    iElement.append(metaTemplate);
                },
                post: function postLink(scope, iElement, iAttrs, controller) {
                    // compile the template after the scope has been initialised
                    $compile(iElement.contents())(scope);
                }
            }
        }
    };
}]);