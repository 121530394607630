'use strict';


if (!Array.prototype.last){
    Array.prototype.last = function(){
        return this[this.length - 1];
    };
};

//user accounts app
var baseApp = angular.module('headart', [
    'ui.router',
    'ngAnimate',
    'ngResource',
    'ngSanitize',
    'debounce',
    'webStorageModule',

    'headart.error',

    'headart.auth.services',
    'headart.auth.directives',
    'headart.auth.controllers',

    'headart.general.controllers',
]);

/*--General Config--*/
baseApp.config(["$logProvider", function($logProvider) {
    //$logProvider.debugEnabled(CONFIG.DEBUG);
    $logProvider.debugEnabled(false);
}]);


/*---http interceptors---*/
baseApp.config(["$provide", "$httpProvider", function($provide, $httpProvider) {

    // allow laravel to detect requests as being "ajax"
    $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    //@TODO check syntax
    $httpProvider.interceptors.push(["$timeout", "$q", "$rootScope", "$injector", function($timeout, $q, $rootScope, $injector) {
        return function(promise) {
            return promise.then(
                function success(successResponse) {
                    return successResponse;
                },
                function error(errorResponse) {
                    if(errorResponse.status == 418){
                        //I'm a teapot! - the CSRF token is invalid, get the new one and then resubmit the request
                        var $http = $injector.get('$http');
                        if (errorResponse.data.data.csrf_token) {
                            $rootScope.user.csrf_token = errorResponse.data.data.csrf_token;
                            errorResponse.config.data.csrf_token = errorResponse.data.data.csrf_token;
                            return $http(errorResponse.config);
                        }
                    }

                    switch (errorResponse.status) {
                        case 0:
                            //request was cancelled
                            break;
                        case 400:
                            break;
                        case 401:
                            //$rootScope.$state.go('error_401');
                            break;
                        case 403:
                            break;
                        case 404:
                            //$rootScope.$state.go('error_404');
                            break;
                        case 500:
                            break;
                        default:
                            break;
                    }

                    return $q.reject(errorResponse);
                });//end of then()
        };
    }]);
}]);


/*---State Definitions---*/
baseApp.config(["$stateProvider", "$locationProvider", "$urlRouterProvider", "$logProvider", "$urlMatcherFactoryProvider", function($stateProvider, $locationProvider, $urlRouterProvider, $logProvider, $urlMatcherFactoryProvider) {
    $locationProvider.html5Mode(true);

    //match urls with or without triling slash
    //$urlMatcherFactoryProvider.strictMode(false);

    /*
        $urlRouterProvider.rule(function ($injector, $location) {
            var path = $location.url();

            console.log($location);

            // check to see if the path already has a slash where it should be
            if (path[path.length - 1] === '/' || path.indexOf('/?') > -1) {
                return;
            }

            if (path.indexOf('?') > -1) {
                return path.replace('?', '/?');
            }

            return path + '/';
        });
    */

    $stateProvider
        .state('app', {
            abstract: true,
            url: ""
        })
        .state('app.home', {
            url: "/",
            // remove for now, so the home page is loaded
            // views: {
            //     "mainView@": {
            //         templateUrl: "/templates/general/homepage.html",
            //         controller: 'HomepageCtrl'
            //     }
            // },
            data: {
                access: "public"
            },
            onEnter: function() {
                // reload to static homepage
                location.reload('/');
            }
        });

}]);



baseApp.run(["$rootScope", "$state", "$log", "$window", "debounce", "AuthService", "MetaData", "$location", function($rootScope, $state, $log, $window, debounce, AuthService, MetaData, $location) {
    $rootScope.$state = $state;
    $rootScope.auth = AuthService;
    // $rootScope.appVersion   = CONFIG.VERSION;

    // set the default metadata and apply it to the page - this is used as a fall back when a piece of meta is missing
    MetaData.setDefaults({
        title: "Head Art",
        description: "Head Art - A new digital hair fashion magazine for the BENELUX",
        site_name: "Head Art",
        author: "@headartbenelux",

        og_locale: "en_GB",
        og_type: "website",

        twitter_card: "summary",
        twitter_site: "@headartbenelux",
        twitter_creator: "@headartbenelux",

        apple_touch_icon_76: "img/icons/cover/icon-76.png",
        apple_touch_icon_120: "img/icons/cover/icon-120.png",
        apple_touch_icon_152: "img/icons/cover/icon-152.png",
        apple_touch_icon_180: "img/icons/cover/icon-180.png"
    });
    MetaData.applyMeta();

    $rootScope.$on("$stateChangeError", function(event, toState, toParams, fromState, fromParams, error) {
        $log.error('Error transitioning to '+toState.name + ' from  '+fromState.name, toState, fromState, error);
        event.preventDefault();
        $state.go('error.unknown');
    });


    //--- Debugging info ---
    $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
        $log.debug("$stateChangeStart", toState.name, Object.keys(toParams).map(function(k) { return k + ":" + toParams[k]; }));
    });

    $rootScope.$on("$stateChangeSuccess", function(event, toState, toParams, fromState, fromParams) {
        // update the MetaData urlabsUrl
        MetaData.meta.og_url = $location.absUrl();

        $log.debug("$stateChangeSuccess", toState.name, Object.keys(toParams).map(function(k) { return k + ":" + toParams[k]; }));
    });

    $rootScope.$on("$stateChangeError", function(event, toState, toParams, fromState, fromParams) {
        $log.debug("$stateChangeError", toState.name, Object.keys(toParams).map(function(k) { return k + ":" + toParams[k]; }));
    });
}]);
