angular.module('headart')       //remember, without [] a new module is not registered and instead an existing one is used
.service('PhantomJs', ["$timeout", function ($timeout) {
    this.htmlReady = function() {
        $timeout(function() {
            if (typeof window.callPhantom == 'function') { 
                window.callPhantom();
            }
        });
    };

    // we can use this to determin if certain actions should be skipped, like preloading images
    this.isPrerendering = typeof window.callPhantom == 'function';
}]);