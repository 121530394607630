
/*-----Global Error Handler-----*/
angular.module('headart.error', [])
.config(["$provide", "$httpProvider", function($provide, $httpProvider) {
    $httpProvider.responseInterceptors.push(["$timeout", "$q", "$rootScope", "$injector", function($timeout, $q, $rootScope, $injector) {
        return function(promise) {
            return promise.then(
                function success(successResponse) {
                    return successResponse;
                }, 
                function error(errorResponse) {
                    if(errorResponse.status == 418){
                        //I'm a teapot! - the CSRF token is invalid, get the new one and then resubmit the request
                        var $http = $injector.get('$http');
                        if (errorResponse.data.data.csrf_token) {
                            $rootScope.user.csrf_token = errorResponse.data.data.csrf_token;
                            errorResponse.config.data.csrf_token = errorResponse.data.data.csrf_token;
                            return $http(errorResponse.config);
                        }
                    }

                    switch (errorResponse.status) {
                        case 0:
                            //request was cancelled
                            break;
                        case 400:
                            break;
                        case 401:
                            //$rootScope.$state.go('error_401');
                            break;
                        case 403:
                            break;
                        case 404:
                            //$rootScope.$state.go('error_404');
                            break;
                        case 500:
                            break;
                        default:
                            break;
                    }

                    return $q.reject(errorResponse);
                });//end of then()
        };
    }]);
}]);